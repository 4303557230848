<template>
    <div class="pb-12">
        <DetailViewHeader
            :title="`${profile?.user?.firstName || ''} ${
                profile?.user?.lastName || ''
            }`"
            :options="['Archive']"
            :backLabel="'Contacts'"
            :actionUrl="''"
            :actionLabel="''"
            :on-back="
                () =>
                    $router.push({
                        name: 'Venture Contacts',
                        params: { ventureId: $route.params.ventureId },
                    })
            "
            :badge-text="convertEnumToRead(profile?.status)"
            :isActive="true"
            customClass="mx-2 sm:mx-6"
            @onClickItem="() => (isOpenRemove = true)"
            class="mt-4"
        />
        <Loader v-if="isPageLoading" :isLoading="isPageLoading" />
        <SubNavigationBar
            @onSelect="(item) => (selectedTab = item)"
            :hide-icon="false"
            :tabs="getTabItems()"
            :currentTab="selectedTab"
            customClass="mt-4 mx-0 sm:mx-8"
            v-if="!isPageLoading"
        />

        <div v-if="!isPageLoading" class="flex flex-col flex-1">
            <div class="space-y-10" v-if="selectedTab.name === 'Details'">
                <div
                    class="sm:mx-8 grid grid-cols-1 lg:grid-cols-7 gap-x-7 pt-10"
                >
                    <div class="col-span-2">
                        <div class="flex justify-between items-center">
                            <Text
                                size="xl"
                                weight="semibold"
                                color="gray-900"
                                custom-class="md:font-bold"
                                :content="'Contact Details'"
                            />
                        </div>
                        <div>
                            <p class="leading-4 mt-4">
                                <Text
                                    size="xs"
                                    color="black"
                                    weight="base"
                                    :content="`Contacts are real people that you know, have been introduced to, or have found online. We will generally have either their real contact info like name and email or social media specific info and just their username.`"
                                    element="span"
                                />
                            </p>
                        </div>
                    </div>
                    <div class="col-span-5">
                        <div class="h-fit rounded-t-md">
                            <div
                                class="px-5 py-8 grid grid-cols-2 gap-x-4 gap-y-5 bg-white border-t border-l border-r border-gray-300 rounded-t-md"
                            >
                                <div
                                    v-for="field in formGroup[0].fields"
                                    :key="field.id"
                                    :class="`col-span-${field.cols} relative`"
                                >
                                    <Input
                                        v-if="
                                            field.inputType === 'TEXT' ||
                                            field.inputType === 'DATETIME-LOCAL'
                                        "
                                        :label="field.label"
                                        :type="field.inputType.toLowerCase()"
                                        :placeholder="field.placeholder"
                                        v-model:value="
                                            formData[field.modelField]
                                        "
                                    />
                                    <Textarea
                                        v-else-if="
                                            field.inputType === 'TEXTAREA'
                                        "
                                        :label="field.label"
                                        :type="field.inputType.toLowerCase()"
                                        :placeholder="field.placeholder"
                                        v-model:value="
                                            formData[field.modelField]
                                        "
                                    />
                                    <div
                                        v-else-if="
                                            field.inputType === 'DROPDOWN'
                                        "
                                    >
                                        <div class="explanation-wrapper">
                                            <span
                                                class="transititext-primary text-primary transition duration-100 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                                                data-te-toggle="tooltip"
                                                title="Target customer for contact cannot be changed since you have started the interview/survey process"
                                            >
                                                <Icon
                                                    custom-class="flex flex-shrink-0 cursor-pointer"
                                                    name="ExclamationCircleIcon"
                                                    size="5"
                                                    color="gray-500"
                                                    v-if="
                                                        field.modelField ===
                                                            'targetCustomer' &&
                                                        this?.profile?.status &&
                                                        this?.profile
                                                            ?.status !==
                                                            'NEW_CONTACT'
                                                    "
                                                />
                                            </span>
                                        </div>
                                        <Select
                                            :label="field.label"
                                            :placeholder="field.placeholder"
                                            v-model:selected-item="
                                                formData[field.modelField]
                                            "
                                            :items="JSON.parse(field.options)"
                                            :disabled="
                                                field.modelField ===
                                                    'targetCustomer' &&
                                                this.profile?.status !==
                                                    'NEW_CONTACT'
                                                    ? true
                                                    : false
                                            "
                                        />
                                    </div>
                                    <AutoComplete
                                        v-else-if="
                                            field.inputType === 'AUTOCOMPLETE'
                                        "
                                        :label="field.label"
                                        :listData="JSON.parse(field.options)"
                                        :placeholder="`${
                                            field.placeholder
                                                ? field.placeholder
                                                : 'Start typing to filter'
                                        }`"
                                        v-model:selectedValue="
                                            formData[field.modelField]
                                        "
                                    />
                                    <div
                                        v-else-if="field.modelField === 'info'"
                                    >
                                        <div
                                            class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300"
                                        >
                                            <div
                                                v-for="(contact, i) in contacts"
                                                :key="contact.type"
                                                class="grid grid-cols-4 py-3 px-2 gap-x-2"
                                            >
                                                <div class="col-span-2">
                                                    <Text
                                                        v-if="!contact.isEdit"
                                                        size="sm"
                                                        weight="semibold"
                                                        color="black"
                                                        :content="contact.value"
                                                    />
                                                    <Input
                                                        v-model:value="
                                                            contact.value
                                                        "
                                                        v-else
                                                        custom-class="mt-1"
                                                        :inputMask="
                                                            contact.type
                                                                ?.name ===
                                                            'Phone'
                                                                ? '(###) ###-####'
                                                                : null
                                                        "
                                                        :placeholder="
                                                            contact.type
                                                                ?.name ===
                                                            'Phone'
                                                                ? 'Please input your phone number'
                                                                : contact.type
                                                                      ?.name ===
                                                                  'Email'
                                                                ? 'Please input your email address'
                                                                : contact.type
                                                                      ?.name ===
                                                                  'Social'
                                                                ? 'Please input your social name'
                                                                : ''
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="col-span-1 flex space-x-2"
                                                >
                                                    <div
                                                        v-if="contact.isEdit"
                                                        class="w-40"
                                                    >
                                                        <Select
                                                            v-model:selectedItem="
                                                                contact.type
                                                            "
                                                            :items="
                                                                contactTypes
                                                            "
                                                        />
                                                    </div>
                                                    <div
                                                        v-if="
                                                            contact.isEdit &&
                                                            contact.type
                                                                ?.name ===
                                                                'Social'
                                                        "
                                                        class="w-40"
                                                    >
                                                        <Select
                                                            v-model:selectedItem="
                                                                contact.handle
                                                            "
                                                            :items="socialTypes"
                                                        />
                                                    </div>
                                                    <Badge
                                                        v-else-if="
                                                            !contact.isEdit
                                                        "
                                                        :label="contact.type"
                                                        :icon-name="
                                                            contact.icon
                                                        "
                                                        isLeft
                                                        bg-color="gray-500"
                                                        is-rounded
                                                        px="3"
                                                        py="1"
                                                        text-color="white"
                                                    />
                                                </div>
                                                <div
                                                    class="col-span-1 flex justify-end items-center"
                                                >
                                                    <a
                                                        @click="
                                                            editContact(
                                                                contact,
                                                                i
                                                            )
                                                        "
                                                        v-if="!contact.isEdit"
                                                        class="px-1"
                                                    >
                                                        <Icon
                                                            name="PencilAltIcon"
                                                            color="primary-600"
                                                            class="hover:text-primary-900"
                                                        />
                                                    </a>
                                                    <a
                                                        @click="
                                                            onEditInfo(contact)
                                                        "
                                                        v-if="contact.isEdit"
                                                        class="px-1"
                                                    >
                                                        <Icon
                                                            name="CheckIcon"
                                                            color="green-600"
                                                            class="hover:text-green-900"
                                                        />
                                                    </a>
                                                    <a
                                                        @click="
                                                            onRemoveInfo(
                                                                contact,
                                                                i
                                                            )
                                                        "
                                                        v-if="!contact.isEdit"
                                                        class="px-1"
                                                    >
                                                        <Icon
                                                            name="TrashIcon"
                                                            color="red-600"
                                                            class="hover:text-red-900"
                                                        />
                                                    </a>
                                                    <a
                                                        @click="
                                                            onBackInfo(
                                                                contact,
                                                                i
                                                            )
                                                        "
                                                        v-if="contact.isEdit"
                                                        class="px-1"
                                                    >
                                                        <Icon
                                                            name="XIcon"
                                                            color="red-600"
                                                            class="hover:text-red-900"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <Text
                                            @click="onAddInfo"
                                            size="sm"
                                            color="primary-600"
                                            content="+ Add"
                                            decoration="underline"
                                            custom-class="cursor-pointer mt-2"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                class="flex flex-row justify-between w-full bg-gray-100 border-l border-r border-t border-b rounded-b-md border-gray-300"
                            >
                                <div />
                                <div
                                    class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5"
                                >
                                    <Button
                                        content="Cancel"
                                        :variant="null"
                                        customClass="bg-white text-black underline px-2 bg-gray-100"
                                    />
                                    <Button
                                        content="Save"
                                        @click="onSaveDetail"
                                        variant="primary"
                                        customClass="ml-4 px-4"
                                        :isLoading="isLoading"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="sm:mx-8 grid grid-cols-1 lg:grid-cols-7 gap-x-7 pt-10"
                >
                    <div class="col-span-2">
                        <div class="flex justify-between items-center">
                            <Text
                                size="xl"
                                weight="semibold"
                                color="gray-900"
                                custom-class="md:font-bold"
                                :content="'Outreach Actions'"
                            />
                        </div>
                        <div>
                            <p class="leading-4 mt-4">
                                <Text
                                    size="xs"
                                    color="black"
                                    weight="base"
                                    :content="`Use these personalized messages to quickly request interviews and survey responses, You can either send it as an email or copy the message to your clipboard for DMing.`"
                                    element="span"
                                />
                            </p>
                        </div>
                    </div>
                    <div class="col-span-5">
                        <ContactOutreachActions
                            v-if="items?.length > 0"
                            @onEditTemplate="onEditTemplate"
                            @onSendEmail="onSendEmail"
                            :items="items"
                            :profile="profile"
                        />
                    </div>
                </div>
                <div
                    v-if="survey?.id"
                    class="sm:mx-8 grid grid-cols-1 lg:grid-cols-7 gap-x-7 pt-10"
                >
                    <div class="col-span-2">
                        <div class="flex justify-between items-center">
                            <Text
                                size="xl"
                                weight="semibold"
                                color="gray-900"
                                custom-class="md:font-bold"
                                :content="'Survey Link'"
                            />
                        </div>
                        <div>
                            <p class="leading-4 mt-4">
                                <Text
                                    size="xs"
                                    color="black"
                                    weight="base"
                                    :content="`This is the unique link that is included in your outreach actions. If you have multiple surveys, you can find each link here.`"
                                    element="span"
                                />
                            </p>
                        </div>
                    </div>
                    <div class="col-span-5">
                        <div class="h-fit rounded-t-md">
                            <div
                                class="px-5 py-8 bg-white border border-gray-300 rounded-md"
                            >
                                <div class="flex space-x-4 items-center">
                                    <Text
                                        size="lg"
                                        weight="semibold"
                                        color="black"
                                        :content="survey?.name"
                                    />
                                    <a
                                        @click="() => (isShowList = true)"
                                        class="relative"
                                    >
                                        <Icon
                                            name="ChevronDownIcon"
                                            size="5"
                                            is-outline
                                            color="gray-600"
                                        />
                                        <div
                                            v-if="isShowList"
                                            v-click-outside="
                                                () => (isShowList = false)
                                            "
                                            class="absolute z-10 border border-gray-300 bg-white py-1 w-40"
                                        >
                                            <button
                                                @click.stop="
                                                    () => {
                                                        survey = item;
                                                        isShowList = false;
                                                    }
                                                "
                                                v-for="item in profile
                                                    ?.targetCustomerUser
                                                    ?.surveys"
                                                :key="item.id"
                                                class="px-2 py-1 hover:bg-gray-100 w-full space-x-2 flex justify-between items-center"
                                                :class="
                                                    item?.id === survey?.id
                                                        ? 'bg-gray-200'
                                                        : ''
                                                "
                                            >
                                                <Text
                                                    size="xs"
                                                    color="black"
                                                    :content="item?.name"
                                                    custom-class="flex flex-1 w-full truncate"
                                                />
                                                <Icon
                                                    @click.stop="
                                                        () => {
                                                            isOpenRemoveSurvey = true;
                                                            isShowList = false;
                                                        }
                                                    "
                                                    name="TrashIcon"
                                                    color="rose-600"
                                                    size="5"
                                                    custom-class="cursor-pointer"
                                                />
                                            </button>
                                        </div>
                                    </a>
                                </div>
                                <div
                                    class="mb-3 border rounded-md border-gray-300 relative px-3 py-2 flex justify-between items-center mt-1"
                                >
                                    <input
                                        autocomplete="off"
                                        :value="`https://${Settings.mainDomain}/#/survey/${survey?.id}?contactId=${this.profile?.id}`"
                                        disabled
                                        class="border-none outline-none focus:ring-0 focus:border-none focus:outline-none w-full bg-white"
                                    />
                                    <Icon
                                        @click="
                                            onCopyUrl(
                                                `https://${Settings.mainDomain}/#/survey/${survey?.id}?contactId=${this.profile?.id}`
                                            )
                                        "
                                        name="DocumentDuplicateIcon"
                                        size="6"
                                        color="gray-500"
                                        hover-text-color="indigo-500"
                                        custom-class="cursor-pointer"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="sm:mx-8 grid grid-cols-1 lg:grid-cols-7 gap-x-7 pt-10"
                    v-if="isShowDemographic"
                >
                    <div class="col-span-2">
                        <div class="flex justify-between items-center">
                            <Text
                                size="xl"
                                weight="semibold"
                                color="gray-900"
                                custom-class="md:font-bold"
                                :content="'Demographics'"
                            />
                        </div>
                        <div>
                            <p class="leading-4 mt-4">
                                <Text
                                    size="xs"
                                    color="black"
                                    weight="base"
                                    :content="`These are the details your contact has shared with you in their survey response and eventually help you segment your response data to find more motivated subsections of the market.`"
                                    element="span"
                                />
                            </p>
                        </div>
                    </div>
                    <div class="col-span-5">
                        <div class="shadow-lg h-fit rounded-md">
                            <div
                                class="px-8 py-8 grid grid-cols-1 gap-4 sm:grid-cols-2 bg-white border border-gray-300 rounded-md"
                            >
                                <Text
                                    size="xl"
                                    weight="semibold"
                                    color="gray-900"
                                    :content="'Demographics'"
                                    custom-class="col-span-2"
                                />
                                <div
                                    v-for="key in Object.keys(demographics)"
                                    :key="key"
                                >
                                    <AutoComplete
                                        v-if="
                                            demographics[key].type === 'string'
                                        "
                                        :label="demographics[key].label"
                                        v-model:selected-value="
                                            demographics[key].value
                                        "
                                        :list-data="demographics[key].options"
                                    />
                                    <MultiSelectDropdown
                                        v-else
                                        is-obj
                                        :label="demographics[key].label"
                                        disabled
                                        v-model:selected="
                                            demographics[key].value
                                        "
                                        :options="demographics[key].options"
                                        class="max-w-7xl"
                                        :description="
                                            demographics[key].helpText
                                        "
                                    />
                                </div>

                                <MultiSelectDropdown
                                    :radioOptions="['Interest', 'Activity']"
                                    v-for="key in Object.keys(interests)"
                                    is-obj
                                    disabled
                                    :key="key"
                                    :label="interests[key].label"
                                    v-model:selected="interests[key].value"
                                    :options="interests[key].options || []"
                                    class="col-span-2 max-w-7xl"
                                    :description="interests[key].helpText"
                                />
                            </div>
                            <!-- <div
						class="flex flex-row justify-between w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md">
						<div />
						<div class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5">
							<Button content="Cancel" :variant="null" customClass="bg-white text-black underline px-2 bg-gray-100" />
							<Button @click="onSaveDemographic" content="Save" variant="primary" customClass="ml-4 px-4"
								:isLoading="isLoading" />
						</div>
					</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="mt-4 mx-0 sm:mx-8 grid grid-cols-1 sm:grid-cols-2 gap-4"
                v-else-if="selectedTab.name === 'Interviews'"
            >
                <!-- <EmptyState
                v-if="interviews?.length === 0"
                class="bg-white col-span-2"
                isFull
                :icon="emptyViewBinding.icon"
                :title="emptyViewBinding.title"
                :description="emptyViewBinding.description"
                :btnText="emptyViewBinding.btnText"
                :action="() => onAddInterview()"
            /> -->
                <div class="space-y-5 mb-10">
                    <a class="flex items-center space-x-10">
                        <Text
                            size="lg"
                            weight="semibold"
                            color="black"
                            :content="selectedInterviewScript?.name"
                        />
                        <div class="relative">
                            <Icon
                                name="ChevronDownIcon"
                                size="5"
                                isOutline
                                color="gray-600"
                                custom-class="cursor-pointer"
                                @click="isShowInterviewList = true"
                            />
                            <div
                                v-if="isShowInterviewList"
                                v-click-outside="
                                    () => (isShowInterviewList = false)
                                "
                                class="absolute z-10 border border-gray-300 bg-white py-1 w-56"
                            >
                                <button
                                    @click.stop="
                                        () => {
                                            selectedInterviewScript = item;
                                            isShowInterviewList = false;
                                        }
                                    "
                                    v-for="item in profile?.targetCustomerUser
                                        ?.interviewScripts"
                                    :key="item.id"
                                    class="px-2 py-1 hover:bg-gray-100 w-full space-x-2 flex justify-between items-center"
                                    :class="
                                        item?.id === selectedInterviewScript?.id
                                            ? 'bg-gray-200'
                                            : ''
                                    "
                                >
                                    <Text
                                        size="xs"
                                        color="black"
                                        :content="item?.name"
                                        custom-class="flex flex-1 w-full truncate"
                                    />
                                    <Icon
                                        @click.stop="
                                            () => {
                                                onDeleteInterview(
                                                    selectedInterview
                                                );
                                                isShowInterviewList = false;
                                            }
                                        "
                                        name="TrashIcon"
                                        color="rose-600"
                                        size="5"
                                        custom-class="cursor-pointer"
                                    />
                                </button>
                            </div>
                        </div>
                    </a>
                    <!-- <Text
                    size="md"
                    weight="medium"
                    color="indigo-700"
                    content="+ Interview"
                    decoration="underline"
                    custom-class="cursor-pointer"
                    @click="onCreateAnotherInterview"
                /> -->
                    <div class="shadow-lg h-fit rounded-md">
                        <div
                            class="px-8 py-8 grid grid-cols-1 gap-4 bg-white border-t border-l border-r border-gray-300 rounded-t-md"
                        >
                            <div
                                v-for="field in interviewForm[0].fields"
                                :key="field.id"
                                :class="`col-span-${field.cols}`"
                            >
                                <Input
                                    v-if="
                                        field.inputType === 'TEXT' ||
                                        field.inputType === 'DATETIME-LOCAL'
                                    "
                                    :label="field.label"
                                    :type="field.inputType.toLowerCase()"
                                    :placeholder="field.placeholder"
                                    v-model:value="
                                        selectedInterview[field.modelField]
                                    "
                                    :customClass="
                                        field.modelField ===
                                            'scheduledDateTime' &&
                                        selectedInterview?.isInitial &&
                                        'text-gray-300'
                                    "
                                />
                                <Editor
                                    v-else-if="field.inputType === 'TEXTAREA'"
                                    :label="field.label"
                                    :placeholder="field.placeholder"
                                    v-model="
                                        selectedInterview[field.modelField]
                                    "
                                />
                            </div>
                            <Text
                                size="sm"
                                weight="semibold"
                                color="black"
                                content="Opportunities"
                            />
                            <Table
                                :list="
                                    selectedInterview.opportunities?.filter(
                                        (o) => !o?.isArchived
                                    )
                                "
                                class="flex h-auto overflow-x-auto sm:block"
                                :displayHeaders="false"
                                :displayTableOnly="true"
                            >
                                <template v-slot:multiselect="{ id }">
                                    <div
                                        class="w-full flex justify-center items-center h-full bg-white"
                                    >
                                        <Text
                                            @click="
                                                () => {
                                                    editItem(
                                                        id,
                                                        selectedInterview
                                                    );
                                                }
                                            "
                                            class="underline cursor-pointer mr-5"
                                            :content="'view'"
                                            color="gray-500"
                                            hoverColor="gray-600"
                                            size="sm"
                                            weight="medium"
                                        />
                                        <Text
                                            @click="
                                                () => {
                                                    removeItem(
                                                        id,
                                                        selectedInterview
                                                    );
                                                }
                                            "
                                            class="underline cursor-pointer"
                                            :content="'remove'"
                                            color="red-500"
                                            hoverColor="red-600"
                                            size="sm"
                                            weight="medium"
                                        />
                                    </div>
                                </template>
                            </Table>
                            <div class="relative">
                                <Text
                                    content="Link or Add New"
                                    color="primary-600"
                                    class="flex ml-4 sm:ml-0 underline cursor-pointer"
                                    hoverColor="primary-900"
                                    size="sm"
                                    weight="medium"
                                    @click="
                                        () => {
                                            isShowOpportunitySelect = true;
                                        }
                                    "
                                />
                                <div
                                    v-if="isShowOpportunitySelect"
                                    v-click-outside="
                                        () => {
                                            isShowOpportunitySelect = false;
                                            selectedOpportunities = [];
                                        }
                                    "
                                >
                                    <OpportunityList
                                        :selectedOpportunities="
                                            selectedOpportunities
                                        "
                                        @onClickItem="
                                            (opportunity) =>
                                                onSelectOpportunity(opportunity)
                                        "
                                        :opportunities="
                                            opportunities?.filter(
                                                (o) =>
                                                    !selectedInterview.opportunities?.find(
                                                        (op) => op?.id === o?.id
                                                    )
                                            )
                                        "
                                        @onAdd="
                                            (searchText) =>
                                                onAddOpportunities({
                                                    ...selectedInterview,
                                                    searchText,
                                                })
                                        "
                                        :isLoading="
                                            isLoading &&
                                            savingPart === 'OPPORTUNITY'
                                        "
                                        :customClass="'left-0 top-6'"
                                        @onDone="
                                            () =>
                                                onAddOpportunities({
                                                    ...selectedInterview,
                                                    searchText: null,
                                                })
                                        "
                                    />
                                </div>
                            </div>
                            <Text
                                size="sm"
                                weight="medium"
                                color="gray-600"
                                content="Interviews aren't marked as 'complete' until at least one opportunity is linked to it."
                                custom-class="italic"
                            />
                        </div>
                        <div
                            class="flex flex-row w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md"
                        >
                            <div
                                class="flex flex-row items-end justify-end w-full my-4 mr-4 sm:mr-3 lg:mr-5"
                            >
                                <Button
                                    content="Cancel"
                                    :variant="null"
                                    customClass="bg-white text-black underline px-2 bg-gray-100"
                                />
                                <Button
                                    @click="
                                        () => onSaveInterview(selectedInterview)
                                    "
                                    content="Save"
                                    variant="primary"
                                    customClass="ml-4 px-4"
                                    :isLoading="
                                        savingPart === 'INTERVIEW' && isLoading
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="interviews?.length > 0" class="space-y-5">
				<Text size="lg" weight="semibold" color="black" content="Script (6/10 Interview Script)" />
				<AccordionWithList bg-color="white" :accordion="accordion" />
			</div> -->
                <div class="space-y-3">
                    <div class="p-4">
                        <div class="flex justify-between items-center">
                            <div class="flex space-x-4 items-center">
                                <Text
                                    size="lg"
                                    weight="semibold"
                                    color="black"
                                    :content="selectedInterviewScript?.name"
                                />
                            </div>
                        </div>
                        <div
                            class="mt-5 space-y-4"
                            v-for="(interview, i) in getInterviewAccordions(
                                selectedInterviewScript
                            )"
                            :key="interview.id"
                        >
                            <AccordionWithList
                                :isInitialOpen="
                                    i === index &&
                                    selectedInterviewScript?.id ===
                                        selectedInterviewScript?.id
                                "
                                :accordion="interview"
                                leading
                                hideBottom
                                isEdit
                                @onOpen="
                                    () => {
                                        index = i;
                                        selectedInterviewScript =
                                            selectedInterviewScript;
                                    }
                                "
                                @onEdit="onEditScript"
                            >
                                <div
                                    class="p-4"
                                    v-html="
                                        getBodyWithVariables(interview.content)
                                    "
                                ></div>
                            </AccordionWithList>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="mt-4 mx-0 sm:mx-8 gap-4"
                v-else-if="selectedTab.name === 'Survey Data'"
            >
                <Table
                    v-if="userSurveys?.length > 0"
                    :list="getFiltered(userSurveys)"
                    class="mt-5 w-full"
                    @onClickItem="(item, id) => onClickSurvey(item, id)"
                    isShowMagnitude
                    isClickable
                />
                <EmptyState
                    v-if="userSurveys?.length === 0"
                    class="bg-white"
                    isFull
                    :icon="emptySurvey.icon"
                    :title="emptySurvey.title"
                    :description="emptySurvey.description"
                    :btnText="emptySurvey.btnText"
                />
                <CardFooterWithPageButtons
                    v-if="userSurveys?.length > 0"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :onChangePage="onChangePage"
                    :onChangePerPage="onChangePerPage"
                    :total-records="userSurveys?.length"
                    class="px-0 bg-opacity-0"
                />
            </div>
        </div>
        <WithNoSwiper
            v-bind="{
                isShow: isShowOpportunity,
                isSaving: isSaving,
                list: opportunityForm,
                isHelp: true,
                modelKey: 'modelField',
                title: 'Add an Opportunity',
                content:
                    'Opportunities are things that people do and sometimes have problems doing.',
            }"
            :saveForm="saveOpportunity"
            @onClose="
                () => {
                    isShowOpportunity = false;
                }
            "
        ></WithNoSwiper>
        <SurveyResponseDetail
            @onClose="() => (isShowDetail = false)"
            :isShow="isShowDetail"
            :title="selectedUserSurvey?.opportunity?.name"
            :opportunity="selectedUserSurvey?.opportunity"
            :userSurvey="selectedUserSurvey"
            @goDetail="
                () => {
                    isShowDetail = false;
                    $router.push({
                        name: 'Venture Opportunity Detail',
                        params: {
                            ventureId: $route.params.ventureId,
                            id: selectedUserSurvey?.opportunity?.id,
                        },
                    });
                }
            "
        />
        <DeleteModal
            :isShow="isOpenRemove"
            @onClose="() => (isOpenRemove = false)"
            :title="`Delete <b>${
                (profile?.user?.firstName || '') +
                ' ' +
                (profile?.user?.lastName || '')
            }</b>?`"
            :description="`Are you sure you want to delete this <b>Contact</b>? They will remove any connected <b>interviews</b> and <b>survey responses</b>.`"
            yes-btn-text="Delete"
            no-btn-text="Cancel"
            icon="TrashIcon"
            yesBtnVariant="danger"
            noBtnVariant="secondary"
            :on-no-click="() => (isOpenRemove = false)"
            :on-yes-click="() => onRemove()"
        />
        <DeleteModal
            :isShow="isShow"
            @onClose="() => (isOpenRemove = false)"
            :title="`Delete <b>Contact Info</b>?`"
            :description="`Are you sure you want to delete this <b>Contact Info</b>?.`"
            yes-btn-text="Delete"
            no-btn-text="Cancel"
            icon="TrashIcon"
            yesBtnVariant="danger"
            noBtnVariant="secondary"
            :on-no-click="() => (isShow = false)"
            :on-yes-click="() => onRemoveContactInfo()"
        />
        <DeleteModal
            :is-show="isOpenRemoveSurvey"
            @onClose="() => (isOpenRemoveSurvey = false)"
            :title="`Archive this <b>Survey</b>?`"
            :description="`Are you sure you want to archive the <b>${survey?.name}</b>? They will remove all responses and associated data.`"
            yes-btn-text="Archive"
            no-btn-text="Cancel"
            icon="TrashIcon"
            yesBtnVariant="danger"
            noBtnVariant="secondary"
            :on-no-click="() => (isOpenRemoveSurvey = false)"
            :on-yes-click="() => onRemoveSurvey()"
        />
    </div>
</template>

<script>
import ListViewItem from "@/components/organisms/ListViewItem/ListViewItem";
import DetailViewHeader from "@/components/molecules/DetailViewHeader/DetailViewHeader";
import SubNavigationBar from "@/components/molecules/SubnavigationBar/SubnavigationBar.vue";
import { c } from "@/components/constants";
import {
    contactDetailForm,
    interviewForm,
    opportunityForm,
    subContactForm,
} from "../../.storybook/sampleData";
import Button from "@/components/atoms/Button/Button";
import Input from "@/components/molecules/Inputs/Component/Component";
import Select from "@/components/molecules/Selects/Component/Component";
import Textarea from "@/components/molecules/Textareas/Simple/Simple";
import AutoComplete from "@/components/molecules/AutoComplete/AutoComplete";
import ContactOutreachActions from "@/components/molecules/ContactOutreachActions/ContactOutreachActions";
import Text from "@/components/atoms/Text/Text";
import Badge from "@/components/molecules/Badge/Basic/Basic";
import Icon from "@/components/atoms/Icons/Icons";
import MultiSelectDropdown from "@/components/molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown";
import AccordionWithList from "@/components/molecules/Accordions/AccordionWithList/AccordionWithList";
import Table from "@/components/organisms/Tables/Simple/Simple";
import WithNoSwiper from "@/components/organisms/Modals/WithNoSwiper/WithNoSwiper";
import EmptyState from "@/components/organisms/EmptyState/Simple/Simple.vue";
import SurveyResponseDetail from "../components/organisms/Modals/SurveyResponseDetail/SurveyResponseDetail.vue";
import { json } from "overmind";
import moment from "moment";
import DeleteModal from "@/components/organisms/Modals/SimpleAlert/SimpleAlert";
import {
    convertEnumToRead,
    stripHtml,
    convertActionWord,
} from "@/utils/utils.js";
import { Settings } from "../../settings";
import { upperFirst } from "lodash";
import vClickOutside from "click-outside-vue3";
import PreviewSurvey from "@/components/organisms/Modals/PreviewSurvey/PreviewSurvey";
import slugify from "slugify";
import useClipboard from "vue-clipboard3";
import OpportunityList from "@/components/organisms/Modals/OpportunityList/OpportunityList";
import CardFooterWithPageButtons from "@/components/organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import Editor from "@/components/molecules/Editor/Editor.vue";
import Loader from "../components/atoms/Loader/Loader.vue";

export default {
    components: {
        ListViewItem,
        DetailViewHeader,
        SubNavigationBar,
        Button,
        Input,
        Select,
        Textarea,
        AutoComplete,
        ContactOutreachActions,
        Text,
        Badge,
        Icon,
        MultiSelectDropdown,
        AccordionWithList,
        Table,
        WithNoSwiper,
        EmptyState,
        SurveyResponseDetail,
        DeleteModal,
        PreviewSurvey,
        OpportunityList,
        CardFooterWithPageButtons,
        Editor,
        Loader,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data() {
        return {
            selectedInterview: null,
            contactTypes: [
                { id: 0, name: "Email" },
                { id: 1, name: "Phone" },
                { id: 2, name: "Social" },
            ],
            socialTypes: [
                { id: 0, name: "Instagram", value: "INSTAGRAM" },
                { id: 1, value: "FACEBOOK", name: "Facebook" },
                { id: 2, value: "TWITTER", name: "Twitter" },
                { id: 3, name: "Tiktok", value: "TIKTOK" },
                { id: 5, name: "LinkedIn", value: "LINKEDIN" },
                { id: 4, name: "Other", value: "OTHER" },
            ],
            isOpenRemove: false,
            isOpenRemoveSurvey: false,
            detailViewHeader: c.detailViewHeader,
            subNavigationBar: c.subNavigationBar,
            formGroup: contactDetailForm,
            formData: {},
            convertEnumToRead,
            items: [],
            isSaving: false,
            contacts: [],
            demographics: {
                age: {
                    label: "Age",
                    name: "age",
                    type: "multiselect",
                    value: [],
                    options: [],
                },
                income: {
                    label: "Income",
                    name: "income",
                    type: "multiselect",
                    value: [],
                    options: [],
                },
                gender: {
                    label: "Gender",
                    name: "gender",
                    type: "multiselect",
                    value: [],
                    options: [],
                },
                location: {
                    label: "Location",
                    name: "location",
                    type: "multiselect",
                    value: [],
                    options: [],
                },
                race: {
                    label: "Race/Ethnicity",
                    name: "race",
                    type: "multiselect",
                    value: [],
                    options: [],
                },
            },
            emptyViewBinding: {
                title: "Get started by adding your first interview!",
                description: "Once your add an interview, it will show up here",
                btnText: "Add an Interview",
                icon: "LightBulbIcon",
            },
            emptySurvey: {
                // title: 'Create a survey',
                description:
                    "This contact doesn't have any survey responses yet. When they respond to your survey, each response will show up here.",
                // btnText: 'Add a Survey',
                // icon: 'LightBulbIcon'
            },
            emptyOpportunity: {
                title: "Let's pick the Opportunities to measure",
                description:
                    "Select up to 3 Opportunities you'd like to measure and compare.",
                btnText: "Select Opportunities",
                icon: "LightBulbIcon",
            },
            interests: {
                interest: {
                    label: "Interests and Activities",
                    name: "interest",
                    type: "autocomplete",
                    value: [],
                    helpText:
                        'If an interest or activity isn\'t on the list, you can type it in and hit "enter" and it will be added as a new tag.',
                    options: [],
                },
            },
            selectedTab: c.subNavigationBar[0],
            interviewForm: interviewForm,
            accordion: {
                name: "Introduction",
                content: [
                    {
                        title: "",
                        items: [
                            "Multiple strap configurations",
                            "Spacious interior with top zip",
                            "Leather handle and tabs",
                            "Interior dividers",
                        ],
                    },
                    {
                        title: "FOLLOW UPS",
                        items: [
                            "Multiple strap configurations",
                            "Spacious interior with top zip",
                            "Leather handle and tabs",
                            "Interior dividers",
                            "Stainless strap loops",
                        ],
                    },
                ],
            },
            surveys: [],
            surveysData: [],
            profile: null,
            form: subContactForm,
            isShow: false,
            isLoading: false,
            interview: {},
            interviews: [],
            opportunities: [],
            isShowOpportunity: false,
            opportunityForm,
            isShowDetail: false,
            selectedOpportunity: null,
            selectedContact: null,
            venture: null,
            isShowDemographic: false,
            Settings,

            // SURVEY
            survey: null,
            c,
            userSurveys: [],
            userSurveysData: [],
            isShowOpportunitySelect: false,
            selectedOpportunities: [],
            savingPart: null,
            selectedUserSurvey: null,
            isShowList: false,
            currentPage: 1,
            perPage: 10,
            isShowInterviewList: false,
            selectedInterview: null,
            selectedInterviewScript: null,
            index: 0,
            isPageLoading: false,
        };
    },
    watch: {
        items: {
            async handler(val) {
                this.onUpdateState();
            },
            deep: true,
        },
        selectedInterviewScript: {
            handler(val) {
                this.getInterviewData();
            },
            deep: true,
        },
        "selectedInterview.scheduledDateTime": function (val) {
            if (
                this.selectedInterview?.id === "0" &&
                Math.abs(
                    new Date().getTime() -
                        new Date(
                            this.selectedInterview?.scheduledDateTime
                        ).getTime()
                ) >
                    24 * 60 * 60 * 1000
            ) {
                this.selectedInterview.isInitial = false;
            }
        },
    },
    methods: {
        getFiltered(items) {
            return items?.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            );
        },
        onChangePage(page) {
            this.currentPage = page;
        },
        onChangePerPage(count) {
            this.perPage = count;
        },
        async onRemoveSurvey() {
            this.isOpenRemoveSurvey = false;
            await this.actions.survey.deleteSurvey({
                where: { id: this.survey?.id },
            });
            const index = this.profile?.targetCustomerUser?.surveys?.findIndex(
                (s) => s?.id === this.survey?.id
            );
            this.profile.targetCustomerUser.surveys.splice(index, 1);
            this.survey =
                this.profile?.targetCustomerUser?.surveys?.reverse()?.[0];
        },
        editItem(id, item) {
            this.$router.push({
                name: "Venture Opportunity Detail",
                params: {
                    ventureId: this.$route.params?.ventureId,
                    id: item.opportunities[id].id,
                },
            });
        },
        async removeItem(id, item) {
            console.log(id, item);
            let params = {
                where: { id: this.profile?.id },
                data: {
                    interviews: {
                        update: [
                            {
                                where: { id: item?.id },
                                data: {
                                    opportunities: {
                                        disconnect: [
                                            { id: item.opportunities[id].id },
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                },
            };
            const { saveProfile } = await this.actions.profile.saveProfile(
                params
            );
            await this.actions.milestone.calculateMilestone({
                ventureId: this.$route.params?.ventureId,
            });
            this.profile = saveProfile;
            this.getInterviewData();
        },
        async onAddOpportunities(item) {
            if (this.isLoading) return false;
            console.log(item, "item");
            try {
                this.isLoading = true;
                this.savingPart = "OPPORTUNITY";
                const create = [],
                    connect = [];
                if (item?.searchText) {
                    create.push({
                        name: item?.searchText,
                        targetCustomerUser: {
                            connect: {
                                id: this.profile?.targetCustomerUser?.id,
                            },
                        },
                        contact: { connect: { id: this.profile?.id } },
                        actionStep: {
                            create: {
                                name: item?.searchText,
                                type: "ACTION",
                                status: "NEW",
                            },
                        },
                    });
                } else {
                    for (
                        let i = 0;
                        i < this.selectedOpportunities?.length;
                        i++
                    ) {
                        connect.push({ id: this.selectedOpportunities[i]?.id });
                    }
                }
                let params = {
                    where: { id: this.profile?.id },
                    data:
                        item.id === "0"
                            ? {
                                  interviews: {
                                      create: [
                                          {
                                              opportunities: {
                                                  create,
                                                  connect,
                                              },
                                              createdBy: {
                                                  connect: {
                                                      id: this.state.currentUser
                                                          ?.id,
                                                  },
                                              },
                                              venture: {
                                                  connect: {
                                                      id: this.$route.params
                                                          ?.ventureId,
                                                  },
                                              },
                                              name: item.name,
                                              scheduledDateTime:
                                                  item?.scheduledDateTime,
                                              interviewScript: {
                                                  connect: {
                                                      id: this
                                                          .selectedInterviewScript
                                                          ?.id,
                                                  },
                                              },
                                          },
                                      ],
                                  },
                              }
                            : {
                                  interviews: {
                                      update: [
                                          {
                                              where: { id: item.id },
                                              data: {
                                                  opportunities: {
                                                      create,
                                                      connect,
                                                  },
                                              },
                                          },
                                      ],
                                  },
                              },
                };
                console.log(params);
                const { saveProfile } = await this.actions.profile.saveProfile(
                    params
                );
                await this.actions.milestone.calculateMilestone({
                    ventureId: this.$route.params?.ventureId,
                });
                this.profile = saveProfile;
                this.getInterviewData();
                this.selectedOpportunities = [];
                this.isShowOpportunitySelect = false;
                this.onUpdateState();
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
                this.savingPart = null;
            }
        },
        onSelectOpportunity(opportunity) {
            console.log(opportunity, "opportunity");
            const opportunities = [...this.selectedOpportunities];
            const index = opportunities?.findIndex(
                (o) => o.id === opportunity?.id
            );
            if (index > -1) {
                opportunities?.splice(index, 1);
            } else {
                opportunities.push(opportunity);
            }
            this.selectedOpportunities = opportunities;
        },
        async onCopyUrl(url) {
            const { toClipboard } = useClipboard();
            try {
                await toClipboard(url);
                this.actions.alert.showSuccess({
                    message: "Copied the url to your clipboard",
                });
            } catch (e) {
                console.error(e);
            }
        },
        onEditScript() {
            this.$router.push({
                name: "Venture Target Customer Detail",
                params: {
                    ventureId: this.$route.params.ventureId,
                    id: this.profile?.targetCustomerUser?.id,
                },
                query: { tab: "Interview" },
            });
        },
        getInterviewAccordions(interviewScript) {
            return [
                {
                    id: "introScript",
                    name: "Introduction_" + interviewScript?.id,
                    content: interviewScript?.introScript,
                    type: "textArea",
                },
                {
                    id: "questionScript",
                    name: "Questions_" + interviewScript?.id,
                    content: interviewScript?.questionScript,
                    type: "textArea",
                },
                {
                    id: "outroScript",
                    name: "Conclusion_" + interviewScript?.id,
                    content: interviewScript?.outroScript,
                    type: "textArea",
                },
            ];
        },
        async populateOpportunityForm() {
            const ventures = await this.actions.venture.getVentures({
                where: { id: this.$route.params?.ventureId },
                getValues: true,
                all: true,
                query: "ventureContactDetail",
            });
            console.log(ventures[0]);
            this.venture = ventures[0];
            if (this.state.actionStep.actionSteps?.length === 0)
                await this.actions.actionStep.getActionSteps({ all: true });
            // if (this.state.economy.economies?.length === 0)
            await this.actions.economy.getEconomies({ all: true });
            const actionSteps = [],
                economies = [];
            this.state.actionStep.actionSteps
                ?.filter((a) => a?.status === "APPROVED")
                .map((o) => actionSteps?.push({ ...o, label: o?.name }));
            this.state.economy.economies.map((o) =>
                economies?.push({
                    ...o,
                    name: o?.problem,
                    label: o?.problem,
                })
            );
            this.opportunityForm[0].fields[1].options = JSON.stringify(
                this.venture.targetCustomerUsers?.filter((t) => !t?.isArchived)
            );
            this.opportunityForm[0].fields[3].options =
                JSON.stringify(actionSteps);
            this.opportunityForm[0].fields[6].options = JSON.stringify(
                economies?.filter((e) => e?.name)
            );
            this.formGroup[0].fields[2].options = JSON.stringify(
                this.venture?.targetCustomerUsers?.filter((t) => !t?.isArchived)
            );
        },
        async onSaveOpportunity(action, interview) {
            try {
                const opportunityParams = {
                    type: "DESCRIPTORS",
                    targetCustomerUser: {
                        connect: { id: this.profile?.targetCustomerUser?.id },
                    },
                    name: "",
                    venture: { connect: { id: this.$route.params?.ventureId } },
                    actionStep: {
                        create: {
                            name: action,
                            type: "ACTION",
                            status: "NEW",
                        },
                    },
                };
                const { saveOpportunity } =
                    await this.actions.opportunity.saveOpportunity({
                        data: { ...opportunityParams },
                    });
                this.opportunities.push(saveOpportunity);
                console.log(this.opportunities);
                this.interviews[
                    this.interviews?.findIndex((i) => i.id === interview?.id)
                ].opportunities.push(saveOpportunity);
                this.actions.alert.showSuccess({
                    message: "Added opportunity successfully",
                });
            } catch (e) {
                console.log(e);
            }
        },
        async saveOpportunity(data) {
            if (this.isSaving) return false;
            console.log(data, "data");
            try {
                this.isSaving = true;
                const opportunityParams = {
                    problemContext: data?.problemContext,
                    type: "ACTIVITY",
                    targetCustomerUser: {
                        connect: { id: data?.targetCustomer?.id },
                    },
                    desireText: data?.motivation?.value,
                    problems: {
                        create: [],
                    },
                    name: "",
                    venture: { connect: { id: this.$route.params?.ventureId } },
                };
                data?.problem?.map((problem) => {
                    if (!problem?.id && problem?.name) {
                        opportunityParams.problems.create.push({
                            type: "PROBLEM",
                            economy: {
                                create: {
                                    name: problem?.name,
                                    problem: problem?.name,
                                },
                            },
                        });
                    } else if (problem?.id) {
                        opportunityParams.problems.create.push({
                            type: "PROBLEM",
                            economy: { connect: { id: problem?.id } },
                        });
                    }
                });
                if (data?.action?.id) {
                    opportunityParams.actionStep = {
                        connect: { id: data?.action?.id },
                    };
                } else if (data?.action?.label) {
                    opportunityParams.actionStep = {
                        create: {
                            name: data?.action?.label,
                            type: "ACTION",
                            status: "NEW",
                        },
                    };
                }
                const { saveOpportunity } =
                    await this.actions.opportunity.saveOpportunity({
                        data: {
                            ...opportunityParams,
                        },
                    });
                this.opportunities.push(saveOpportunity);
                console.log(this.opportunities);
                this.interviews[
                    this.interviews?.findIndex(
                        (i) => i.id === this.selectedInterview?.id
                    )
                ].opportunities.push(saveOpportunity);
                this.actions.alert.showSuccess({
                    message: "Added opportunity successfully",
                });
                // this.selectedInterview = null;
                this.isShowOpportunity = false;
            } catch (e) {
                console.log(e);
            } finally {
                this.isSaving = false;
            }
        },
        getTabItems() {
            return [
                {
                    name: "Details",
                    count: null,
                    href: "#",
                    icon: "PencilIcon",
                    current: false,
                },
                {
                    name: "Interviews",
                    count: this.interviews?.filter((o) => !o?.isArchived)
                        ?.length,
                    href: "#",
                    icon: "ChatAlt2Icon",
                    current: false,
                },
                {
                    name: "Survey Data",
                    count: this.userSurveys?.filter((o) => !o?.isArchived)
                        ?.length,
                    href: "#",
                    icon: "ChartBarIcon",
                    current: true,
                },
            ];
        },
        async onSaveDetail() {
            if (this.isLoading) return false;
            console.log(this.formData, "formData");
            this.isLoading = true;
            try {
                const params = {
                    where: { id: this.profile?.id },
                    data: {
                        user: {
                            update: {
                                firstName: this.formData?.firstName,
                                lastName: this.formData?.lastName,
                            },
                        },
                        notes: this.formData?.notes,
                        relationship: this.formData?.relationship?.value,
                    },
                };
                if (this.formData?.targetCustomer?.id) {
                    params.data.targetCustomerUser = {
                        connect: { id: this.formData?.targetCustomer?.id },
                    };
                }
                if (this.formData?.company?.id) {
                    params.data.user.update.company = {
                        connect: { id: this.formData?.company?.id },
                    };
                    this.actions.company.saveCompany({
                        where: { id: this.formData?.company?.id },
                        data: {
                            users: {
                                connect: [{ id: this.profile?.user?.id }],
                            },
                            profiles: {
                                connect: [{ id: this.profile?.id }],
                            },
                        },
                    });
                } else if (this.formData?.company?.label) {
                    params.data.user.update.company = {
                        create: {
                            name: this.formData?.company?.label,
                            nameLower:
                                this.formData?.company?.label?.toLowerCase(),
                            username: slugify(this.formData?.company?.label),
                            users: {
                                connect: [{ id: this.profile?.user?.id }],
                            },
                            profiles: {
                                connect: [{ id: this.profile?.id }],
                            },
                        },
                    };
                }
                if (this.formData?.interviewDate) {
                    if (
                        this.profile?.interviews?.filter((o) => !o?.isArchived)
                            ?.length === 0
                    ) {
                        params.data.interviews = {
                            create: [
                                {
                                    name:
                                        moment(
                                            this.formData?.interviewDate
                                        ).format("MM/DD") + " Interview",
                                    scheduledDateTime: new Date(
                                        this.formData?.interviewDate
                                    ),
                                    timezone:
                                        Intl.DateTimeFormat().resolvedOptions()
                                            .timeZone,
                                    createdBy: {
                                        connect: {
                                            id: this.state.currentUser?.id,
                                        },
                                    },
                                    venture: {
                                        connect: {
                                            id: this.$route.params?.ventureId,
                                        },
                                    },
                                },
                            ],
                        };
                    } else {
                        params.data.interviews = {
                            update: [
                                {
                                    where: {
                                        id: this.profile?.interviews?.[0]?.id,
                                    },
                                    data: {
                                        scheduledDateTime: new Date(
                                            this.formData?.interviewDate
                                        ),
                                    },
                                },
                            ],
                        };
                    }
                }
                console.log(params, "params");
                const { saveProfile } = await this.actions.profile.saveProfile(
                    params
                );
                await this.actions.milestone.calculateMilestone({
                    ventureId: this.$route.params?.ventureId,
                });
                this.profile = saveProfile;
                this.items = this.profile?.targetCustomerUser?.emailTemplates;
                if (this.items?.length > 0) {
                    this.profile?.metadata?.map((item) => {
                        const d = this.items?.find((i) => i?.id === item?.id);
                        if (d) d.isSent = item?.isSent;
                    });
                    this.items?.map((item) => {
                        item.body = this.getBodyWithVariables(item.body);
                        item.subject = this.getBodyWithVariables(item.subject);
                    });
                }
                this.getInterviewData();
                this.onUpdateState();
                this.actions.alert.showSuccess({
                    message: "Updated successfully!",
                });
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
        async onRemove() {
            console.log("Archiving Profile");
            await this.actions.profile.archiveProfile({
                where: { id: this.profile?.id },
            });
            this.actions.alert.showSuccess({ message: "Deleted Successfully" });
            this.isOpenRemove = false;
            this.$router.go(-1);
        },
        async onRemoveContactInfo() {
            const params = {
                where: { id: this.profile?.id },
                data: {
                    user: {
                        update: {
                            emails: {},
                            phones: {},
                            socialHandles: {},
                        },
                    },
                },
            };
            params.data.user.update[
                this.selectedContact.type === "Email"
                    ? "emails"
                    : this.selectedContact.origType === "Phone"
                    ? "phones"
                    : "socialHandles"
            ].delete = [{ id: this.selectedContact.id }];
            const { saveProfile } = await this.actions.profile.saveProfile(
                params
            );
            await this.actions.milestone.calculateMilestone({
                ventureId: this.$route.params?.ventureId,
            });
            this.profile = saveProfile;
            this.getContactInfo();
            this.isShow = false;
        },
        onClickSurvey(item, index) {
            console.log(item, "item", index);
            this.selectedUserSurvey = this.userSurveysData[index];
            this.selectedUserSurvey = {
                ...this.selectedUserSurvey,
                opportunity: {
                    ...this.selectedUserSurvey.opportunity,
                    magnitudeScore: this.selectedUserSurvey?.magnitude,
                    annualizedMagnitude:
                        this.selectedUserSurvey?.annualizedMagnitude,
                    desireScore: this.selectedUserSurvey?.desire,
                    experience: this.selectedUserSurvey?.experience,
                    frequency: this.selectedUserSurvey?.frequency,
                    isActionDone: this.selectedUserSurvey?.isActionDone,
                },
            };
            console.log(this.selectedUserSurvey, "selectedOpportunity");
            this.isShowDetail = true;
        },
        async onDeleteInterview() {
            await this.actions.profile.saveProfile({
                where: { id: this.profile?.id },
                data: {
                    interviews: {
                        delete: [
                            {
                                id: this.selectedInterview?.id,
                            },
                        ],
                    },
                },
            });
            this.actions.alert.showSuccess({
                message: "Deleted interview successfully!",
            });
            this.onUpdateState();
            this.getInterviewData();
        },
        async onSaveInterview(interview) {
            if (this.isLoading) return false;
            console.log(interview);
            const opportunities = [];
            interview.opportunities?.map((o) =>
                opportunities.push({ id: o?.id })
            );
            this.isLoading = true;
            this.savingPart = "INTERVIEW";
            const origInterview = this.profile.interviews?.find(
                (i) => i.id === interview.id
            );
            const deleteOpportunities = [];
            origInterview?.opportunities
                ?.filter(
                    (o) =>
                        !interview?.opportunities?.find(
                            (op) => op?.id === o?.id
                        )
                )
                ?.map((o) => deleteOpportunities?.push({ id: o?.id }));
            try {
                if (interview?.id === "0") {
                    const { saveProfile } =
                        await this.actions.profile.saveProfile({
                            where: { id: this.profile?.id },
                            data: {
                                status: "INTERVIEW_SCHEDULED",
                                interviews: {
                                    create: [
                                        {
                                            name: interview?.name,
                                            description: interview?.description,
                                            recordingUrl:
                                                interview?.recordingUrl,
                                            scheduledDateTime: new Date(
                                                interview?.scheduledDateTime
                                            ),
                                            timezone:
                                                Intl.DateTimeFormat().resolvedOptions()
                                                    .timeZone,
                                            createdBy: {
                                                connect: {
                                                    id: this.state.currentUser
                                                        ?.id,
                                                },
                                            },
                                            venture: {
                                                connect: {
                                                    id: this.$route.params
                                                        ?.ventureId,
                                                },
                                            },
                                            opportunities: {
                                                connect: opportunities,
                                            },
                                            interviewScript: {
                                                connect: {
                                                    id: this
                                                        .selectedInterviewScript
                                                        ?.id,
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        });
                    await this.actions.milestone.calculateMilestone({
                        ventureId: this.$route.params?.ventureId,
                    });
                    this.profile = saveProfile;
                } else {
                    const { saveProfile } =
                        await this.actions.profile.saveProfile({
                            where: { id: this.profile?.id },
                            data: {
                                interviews: {
                                    update: [
                                        {
                                            where: { id: interview?.id },
                                            data: {
                                                name:
                                                    moment(
                                                        interview?.scheduledDateTime
                                                    ).format("MM/DD") +
                                                    " Interview",
                                                description:
                                                    interview?.description,
                                                recordingUrl:
                                                    interview?.recordingUrl,
                                                scheduledDateTime: new Date(
                                                    interview?.scheduledDateTime
                                                ),
                                                timezone:
                                                    Intl.DateTimeFormat().resolvedOptions()
                                                        .timeZone,
                                                createdBy: {
                                                    connect: {
                                                        id: this.state
                                                            .currentUser?.id,
                                                    },
                                                },
                                                venture: {
                                                    connect: {
                                                        id: this.$route.params
                                                            ?.ventureId,
                                                    },
                                                },
                                                opportunities: {
                                                    connect: opportunities,
                                                    disconnect:
                                                        deleteOpportunities,
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        });
                    this.profile = saveProfile;
                    await this.actions.milestone.calculateMilestone({
                        ventureId: this.$route.params?.ventureId,
                    });
                }
                this.actions.alert.showSuccess({
                    message: "Updated interview successfully!",
                });
                this.onUpdateState();
                this.getInterviewData();
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
                this.savingPart = null;
            }
        },
        // onAddInterview() {
        //     this.interviews.push({
        //         id: "0",
        //         name: moment().format("MM/YY") + " Interview",
        //         description: "",
        //         recordingUrl: "",
        //         scheduledDateTime: moment().format("YYYY-MM-DDThh:mm"),
        //         opportunities: [],
        //     });
        // },
        async onSendEmail(item) {
            console.log(item, "item");
            // await this.actions.profile.sendOutreachEmail({
            // 	userId: this.profile?.user?.id,
            // 	profileId: this.profile?.id,
            // 	emailTemplateId: item?.id,
            // 	subject: item.subject,
            // 	body: item.body
            // })
            window.open(
                `mailto:${this.profile?.user?.email}?subject=${encodeURI(
                    item.subject
                )}&body=${encodeURI(stripHtml(item.body))}`
            );
        },
        onEditTemplate(template) {
            this.$router.push({
                name: "Venture Target Customer Detail",
                params: {
                    ventureId: this.$route?.params?.ventureId,
                    id: this.state.currentVenture?.targetCustomerUsers
                        ?.filter((t) => !t?.isArchived)
                        ?.find((u) =>
                            u.contacts?.find((c) => c.id === this.profile.id)
                        )?.id,
                },
                query: {
                    emailTemplate: template?.id,
                },
            });
        },
        async onSaveDemographic() {
            if (this.isLoading) return false;
            const disconnects = [],
                connects = [],
                create = [];
            const form = this.demographics;
            const origTags = this.profile.user?.tags;
            if (form.age.value?.id) {
                const id = form.age.value?.id;
                const origId = origTags?.find((o) => o?.category === "AGE")?.id;
                if (!origId || origId !== id) {
                    connects.push({ id });
                } else if (origId) {
                    disconnects.push({ id: origId });
                }
            }
            if (form.income.value?.id) {
                const id = form.income.value?.id;
                const origId = origTags?.find(
                    (o) => o?.category === "INCOME"
                )?.id;
                if (!origId || origId !== id) {
                    connects.push({ id });
                } else if (origId) {
                    disconnects.push({ id: origId });
                }
            }
            if (form.gender.value?.id) {
                const id = form.gender.value?.id;
                const origId = origTags?.find(
                    (o) => o?.category === "GENDER"
                )?.id;
                if (!origId || origId !== id) {
                    connects.push({ id });
                } else if (origId) {
                    disconnects.push({ id: origId });
                }
            }
            if (form.race.value?.id) {
                const id = form.race.value?.id;
                const origId = origTags?.find(
                    (o) => o?.category === "ETHNICITY"
                )?.id;
                if (!origId || origId !== id) {
                    connects.push({ id });
                } else if (origId) {
                    disconnects.push({ id: origId });
                }
            }

            form.location?.value?.map((l) => {
                if (l?.id && isNaN(l.id)) connects.push({ id: l?.id });
                else
                    create.push({
                        type: "LOCATION",
                        category: "COUNTRY", // TODO need more context
                        name: l.label,
                        nameLower: l.label.toLowerCase(),
                        status: "NEW",
                        targetType: "ALL",
                    });
            });

            this.interests.interest?.value?.map((l) => {
                if (l?.id && isNaN(l.id)) connects.push({ id: l?.id });
                else
                    create.push({
                        type: "DEMOGRAPHIC",
                        category:
                            l?.selectedOption === "Interest"
                                ? "DESCRIPTORS"
                                : "DESCRIPTORS",
                        name: l?.name,
                        targetType: "INDIVIDUALS",
                        status: "NEW",
                    });
            });

            this.isLoading = true;
            const { saveProfile } = await this.actions.profile.saveProfile({
                where: { id: this.profile?.id },
                data: {
                    user: {
                        update: {
                            tags: {
                                create,
                                disconnect: disconnects,
                                connect: connects,
                            },
                        },
                    },
                },
            });
            await this.actions.milestone.calculateMilestone({
                ventureId: this.$route.params?.ventureId,
            });
            this.profile = saveProfile;
            this.isLoading = false;
        },
        async saveForm(data) {
            if (this.isLoading) return false;
            console.log(data, "data");
            this.isLoading = true;
            if (data?.type?.name === "EMAIL") {
                const { saveProfile } = await this.actions.profile.saveProfile({
                    where: { id: this.profile?.id },
                    data: {
                        user: {
                            update: {
                                emails: {
                                    create: [
                                        {
                                            address: data.email,
                                        },
                                    ],
                                },
                            },
                        },
                    },
                });
                await this.actions.milestone.calculateMilestone({
                    ventureId: this.$route.params?.ventureId,
                });
                this.profile = saveProfile;
                this.getContactInfo();
            } else if (data?.type?.name === "PHONE") {
                const { saveProfile } = await this.actions.profile.saveProfile({
                    where: { id: this.profile?.id },
                    data: {
                        user: {
                            update: {
                                phones: {
                                    create: [
                                        {
                                            number: data.phone.replace(
                                                /[^A-Z0-9]/gi,
                                                ""
                                            ),
                                        },
                                    ],
                                },
                            },
                        },
                    },
                });
                await this.actions.milestone.calculateMilestone({
                    ventureId: this.$route.params?.ventureId,
                });
                this.profile = saveProfile;
                this.getContactInfo();
            } else if (data?.type?.name === "SOCIAL") {
                const { saveProfile } = await this.actions.profile.saveProfile({
                    where: { id: this.profile?.id },
                    data: {
                        user: {
                            update: {
                                socialHandles: {
                                    create: [
                                        {
                                            platform: data?.platform?.name,
                                            handle: data?.name,
                                        },
                                    ],
                                },
                            },
                        },
                    },
                });
                await this.actions.milestone.calculateMilestone({
                    ventureId: this.$route.params?.ventureId,
                });
                this.profile = saveProfile;
                this.getContactInfo();
            }
            this.isLoading = false;
            this.isShow = false;
        },
        onRemoveInfo(contact, i) {
            this.selectedContact = contact;
            this.isShow = true;
        },
        onBackInfo(contact, i) {
            if (contact?.id) {
                this.contacts[i] = {
                    type: contact?.type?.name,
                    icon:
                        contact?.type?.name === "Email"
                            ? "MailIcon"
                            : contact?.type?.name === "Phone"
                            ? "PhoneIcon"
                            : "",
                    value: contact.value,
                    id: contact.id,
                };
            } else {
                this.contacts?.splice(i, 1);
            }
        },
        editContact(contact, i) {
            console.log(contact, i, "====");
            try {
                this.contacts[i] = {
                    type: this.contactTypes?.find(
                        (t) => t.name === contact.type
                    ) || { id: 2, name: "Social" },
                    origType: contact.type,
                    value: contact.value,
                    id: contact.id,
                    isEdit: true,
                    handle: this.socialTypes?.find(
                        (s) => s.value === contact?.type
                    ),
                };
            } catch (e) {
                console.log(e);
            }
        },
        async onEditInfo(contact) {
            console.log(contact, "contact");
            if (!contact?.type?.name) {
                this.actions.alert.showError({
                    message: "Please select a type",
                });
                return false;
            }
            if (contact?.type?.name === "Social" && !contact?.value) {
                this.actions.alert.showError({
                    message: "Social name required!",
                });
                return false;
            }
            const params = {
                where: { id: this.profile?.id },
                data: {
                    user: {
                        update: {
                            emails: {
                                create: [],
                                update: [],
                                delete: [],
                            },
                            phones: {
                                create: [],
                                update: [],
                                delete: [],
                            },
                            socialHandles: {
                                create: [],
                                update: [],
                                delete: [],
                            },
                        },
                    },
                },
            };
            if (
                contact?.id &&
                contact?.origType &&
                contact?.origType !== contact?.type?.name
            ) {
                params.data.user.update[
                    contact.origType === "Email"
                        ? "emails"
                        : contact.origType === "Phone"
                        ? "phones"
                        : "socialHandles"
                ].delete = [{ id: contact.id }];
            }
            if (contact?.type?.name === "Phone") {
                if (contact?.id && contact?.origType === contact?.type?.name) {
                    params.data.user.update.phones.update = [
                        {
                            where: { id: contact.id },
                            data: {
                                number: contact?.value?.replace(
                                    /[^A-Z0-9]/gi,
                                    ""
                                ),
                            },
                        },
                    ];
                } else {
                    params.data.user.update.phones.create = [
                        { number: contact?.value },
                    ];
                }
            } else if (contact?.type?.name === "Email") {
                if (contact?.id && contact?.origType === contact?.type?.name) {
                    params.data.user.update.emails.update = [
                        {
                            where: { id: contact.id },
                            data: { address: contact?.value },
                        },
                    ];
                } else {
                    params.data.user.update.emails.create = [
                        { address: contact?.value },
                    ];
                }
            } else if (contact?.type?.name === "Social") {
                if (contact?.id && contact?.origType === contact?.type?.name) {
                    params.data.user.update.socialHandles.update = [
                        {
                            where: { id: contact.id },
                            data: {
                                platform: contact?.handle?.value,
                                handle: contact?.value,
                            },
                        },
                    ];
                } else {
                    params.data.user.update.socialHandles.create = [
                        {
                            platform: contact?.handle?.value,
                            handle: contact?.value,
                        },
                    ];
                }
            }
            const { saveProfile } = await this.actions.profile.saveProfile(
                params
            );
            await this.actions.milestone.calculateMilestone({
                ventureId: this.$route.params?.ventureId,
            });
            this.profile = saveProfile;
            this.getContactInfo();
        },
        onAddInfo() {
            this.contacts.push({
                isEdit: true,
                type: null,
                value: null,
            });
        },
        getContactInfo() {
            this.contacts = [];
            this.profile?.user?.emails?.map((e) => {
                this.contacts.push({
                    type: "Email",
                    icon: "MailIcon",
                    value: e?.address,
                    id: e.id,
                });
            });
            this.profile?.user?.phones?.map((e) => {
                this.contacts.push({
                    type: "Phone",
                    icon: "PhoneIcon",
                    value: e?.number,
                    id: e.id,
                });
            });
            this.profile?.user?.socialHandles?.map((e) => {
                this.contacts.push({
                    type: e?.platform,
                    icon: "TwitterIcon",
                    value: e?.handle,
                    id: e.id,
                });
            });
        },
        async onUpdateState() {
            console.log("ON UPDATE STATE");
            const metadata = [...(this.profile.metadata || [])];
            this.items.map((item, i) => {
                if (metadata[i]) {
                    metadata[i].isSent = !!item.isSent;
                }
            });
            // if (JSON.stringify(metadata) !== JSON.stringify(this.profile.metadata)) {
            const { saveProfile } = await this.actions.profile.saveProfile({
                where: { id: this.profile?.id },
                data: { metadata },
            });
            await this.actions.milestone.calculateMilestone({
                ventureId: this.$route.params?.ventureId,
            });
            this.profile = saveProfile;
            // }
            let status = "NEW_CONTACT";

            console.log(status, "status");

            if (this.items?.[0]?.isSent) {
                status = "INTERVIEW_REQUESTED";
            }
            if (this.items?.[1]?.isSent) {
                status = "INTERVIEW_FOLLOW_UP";
            }
            if (
                this.profile?.interviews?.find(
                    (interview) => interview?.scheduledDateTime
                )
            ) {
                status = "INTERVIEW_SCHEDULED";
            }
            if (this.items?.[2]?.isSent) {
                status = "DAY_OF_REMINDER_SENT";
            }
            if (
                this.profile?.interviews?.find(
                    (interview) => interview?.opportunities?.length > 0
                )
            ) {
                status = "INTERVIEW_COMPLETE";
            }
            if (this.items?.[3]?.isSent) {
                status = "INTERVIEW_THANK_YOU_SENT";
            }
            if (this.items?.[4]?.isSent) {
                status = "SURVEY_REQUESTED";
            }
            if (this.items?.[5]?.isSent) {
                status = "SURVEY_FOLLOW_UP";
            }
            console.log(
                this.userSurveysData,
                this.userSurveysData?.length,
                "this.userSurveysData"
            );
            if (this.userSurveysData?.length > 0) {
                status = "SURVEY_COMPLETE";
            }
            if (this.profile?.openToTalk) {
                status = "OPEN_TO_TALK";
            }
            console.log(status, "STATUS");

            if (status) {
                const { saveProfile } = await this.actions.profile.saveProfile({
                    where: { id: this.profile?.id },
                    data: { status },
                });
                this.profile = saveProfile;
                await this.actions.milestone.calculateMilestone({
                    ventureId: this.$route.params?.ventureId,
                });
            }
            const venture = await this.actions.venture.getVentureById(
                this.$route.params?.ventureId
            );
            let surveyData = [];
            for (
                let i = 0;
                i <
                venture.targetCustomerUsers?.filter((t) => !t?.isArchived)
                    ?.length;
                i++
            ) {
                const targetCustomer = venture.targetCustomerUsers?.filter(
                    (t) => !t?.isArchived
                )[i];
                const data = await this.actions.userSurvey.getUserSurveys({
                    where: {
                        isArchived_not: true,
                        survey: {
                            targetCustomerUser: { id: targetCustomer?.id },
                        },
                    },
                    getValues: true,
                });
                console.log(data, "data");
                surveyData = [...surveyData, ...data];
            }
            venture.surveyData = surveyData;
            this.actions.setCurrentVenture(venture);
        },
        getBodyWithVariables(html) {
            let body = html;
            if (body?.includes("{{firstName}}")) {
                body = body.replace(
                    "{{firstName}}",
                    this.profile?.user?.firstName
                );
            }
            if (body?.includes("{{firstName}}!")) {
                body = body.replace(
                    "{{firstName}}!",
                    this.profile?.user?.firstName + "!"
                );
            }
            if (body?.includes("{{ firstName }}")) {
                body = body.replace(
                    "{{ firstName }}",
                    this.profile?.user?.firstName
                );
            }
            if (body?.includes("{{ firstName }}!")) {
                body = body.replace(
                    "{{ firstName }}!",
                    this.profile?.user?.firstName + "!"
                );
            }
            if (body?.includes("{{ researchPrompt }}")) {
                body = body.replace(
                    "{{ researchPrompt }}",
                    convertActionWord(
                        this.profile?.targetCustomerUser?.researchPrompt
                    )
                );
            }
            if (body?.includes("{{researchPrompt}}")) {
                body = body.replace(
                    "{{researchPrompt}}",
                    convertActionWord(
                        this.profile?.targetCustomerUser?.researchPrompt
                    )
                );
            }
            if (body?.includes("{{targetCustomer}}")) {
                body = body.replace(
                    "{{targetCustomer}}",
                    this.profile?.targetCustomerUser?.name
                );
            }
            if (body?.includes("{{ targetCustomer }}")) {
                body = body.replace(
                    "{{ targetCustomer }}",
                    this.profile?.targetCustomerUser?.name
                );
            }
            if (body?.includes("{{targetCustomerUser.name}}")) {
                body = body.replace(
                    "{{targetCustomerUser.name}}",
                    this.profile?.targetCustomerUser?.name
                );
            }
            if (body?.includes("{{ targetCustomerUser.name }}")) {
                body = body.replace(
                    "{{ targetCustomerUser.name }}",
                    this.profile?.targetCustomerUser?.name
                );
            }
            if (body?.includes("{{ interviewTime + timezone }}")) {
                body = body.replace(
                    "{{ interviewTime + timezone }}",
                    this.profile?.interviews?.[0]?.scheduledDateTime
                        ? moment(
                              this.profile?.interviews?.[0]?.scheduledDateTime
                          ).format("MM/DD/YY h:mm A") +
                              ", " +
                              new Date()
                                  .toLocaleTimeString("en-us", {
                                      timeZoneName: "short",
                                  })
                                  .split(" ")[2]
                        : ""
                );
            }
            if (body?.includes("{{interviewTime + timezone}}")) {
                body = body.replace(
                    "{{interviewTime + timezone}}",
                    this.profile?.interviews?.[0]?.scheduledDateTime
                        ? moment(
                              this.profile?.interviews?.[0]?.scheduledDateTime
                          ).format("MM/DD/YY h:mm A") +
                              ", " +
                              new Date()
                                  .toLocaleTimeString("en-us", {
                                      timeZoneName: "short",
                                  })
                                  .split(" ")[2]
                        : ""
                );
            }
            if (body?.includes("{{ contactSurveyLink }}")) {
                if (this.survey?.id) {
                    body = body.replace(
                        "{{ contactSurveyLink }}",
                        `https://${Settings.mainDomain}/#/survey/${this.survey?.id}?contactId=${this.profile?.id}`
                    );
                } else {
                    body = body.replace("{{ contactSurveyLink }}", "");
                }
            }
            if (body?.includes("{{contactSurveyLink}}")) {
                if (this.survey?.id) {
                    body = body.replace(
                        "{{contactSurveyLink}}",
                        `https://${Settings.mainDomain}/#/survey/${this.survey?.id}?contactId=${this.profile?.id}`
                    );
                } else {
                    body = body.replace("{{contactSurveyLink}}", "");
                }
            }
            return body;
        },
        getInterviewData() {
            this.interviews = this.profile.interviews
                ?.filter((i) => i?.venture?.id === this.$route.params.ventureId)
                .filter((o) => !o?.isArchived);
            this.interviews?.map((interview) => {
                interview.scheduledDateTime = moment(
                    interview?.scheduledDateTime
                ).format("YYYY-MM-DDTHH:mm");
            });
            this.selectedInterview = this.interviews?.find(
                (i) =>
                    i?.interviewScript?.id ===
                        this.selectedInterviewScript?.id && !i?.isArchived
            ) || {
                id: "0",
                name: moment().format("MM/YY") + " Interview",
                description: "",
                recordingUrl: "",
                scheduledDateTime: moment().format("YYYY-MM-DDTHH:mm"),
                opportunities: [],
                isInitial: true,
            };
            console.log(this.selectedInterview, "selectedInterview");
        },
    },
    async created() {
        this.isPageLoading = true;
        this.selectedNav = this.getTabItems()[0];
        try {
            const items = await this.actions.profile.getProfiles({
                where: { id: this.$route.params?.id, isArchived_not: true },
                getValues: true,
            });
            if (this.$route.query?.tab === "Interviews") {
                this.selectedTab = this.getTabItems()[1];
            }
            this.profile = items[0];
            this.selectedInterviewScript =
                this.profile?.targetCustomerUser?.interviewScripts?.[0];
            this.getContactInfo();

            console.log(this.profile, "profile");

            const userSurveys = await this.actions.userSurvey.getUserSurveys({
                where: {
                    profile: { id: this.profile?.id },
                    isArchived_not: true,
                },
                getValues: true,
            });

            console.log(userSurveys, "userSurveys");

            this.userSurveysData = userSurveys;

            console.log(this.userSurveysData, "this.userSurveysData");

            this.onUpdateState();
            this.survey =
                this.profile?.targetCustomerUser?.surveys?.reverse()?.[0];
            this.items = this.profile?.targetCustomerUser?.emailTemplates;
            if (this.items?.length > 0) {
                this.profile?.metadata?.map((item) => {
                    const d = this.items?.find((i) => i?.id === item?.id);
                    if (d) d.isSent = item?.isSent;
                });
                this.items?.map((item) => {
                    item.body = this.getBodyWithVariables(item.body);
                    item.subject = this.getBodyWithVariables(item.subject);
                });
            }

            console.log(this.items, "items");
            this.getInterviewData();
            await this.actions.tag.getTags({
                all: true,
                where: { status: "APPROVED" },
            });
            // await this.actions.company.getCompanies({ all: true });
            await this.actions.company.getCompanies({
                all: true,
                query: "companyDefault",
            });
            const companies = json(this.state.company.companies);
            companies.map((c) => (c.label = c.name));
            this.companies = companies;
            this.formGroup[0].fields[3].options = JSON.stringify(companies);
            this.profile.targetCustomerUser.name = upperFirst(
                this.profile.targetCustomerUser?.name
            );
            console.log(companies, "companies");
            this.formData.firstName = this.profile?.user?.firstName;
            this.formData.lastName = this.profile?.user?.lastName;
            this.formData.targetCustomer = this.profile?.targetCustomerUser;
            this.formData.relationship = JSON.parse(
                this.formGroup[0]?.fields[4]?.options
            )?.find((o) => o.value === this.profile?.relationship);
            this.formData.notes = this.profile?.notes;
            this.formData.company = {
                ...this.profile?.user?.company,
                label: this.profile?.user?.company?.name,
            };
            this.formData.interviewDate =
                this.profile?.interviews[0]?.scheduledDateTime;
            const opportunities =
                await this.actions.opportunity.getOpportunities({
                    where: {
                        venture: {
                            id: this.$route.params?.ventureId,
                            isArchived_not: true,
                        },
                        targetCustomerUser: {
                            id: this.profile?.targetCustomerUser?.id,
                        },
                    },
                    getValues: true,
                });
            console.log(opportunities, "opportunities");
            // opportunities?.map(op => op.name = op.problemContext);
            this.opportunities = opportunities;
            this.populateOpportunityForm();

            userSurveys.map((so) => {
                this.userSurveys.push({
                    id: so?.id,
                    Opportunity: so?.opportunity?.name,
                    Magnitude: so?.magnitude || 0,
                    "Job Done": so?.isActionDone ? "Yes" : "No",
                    Frequency:
                        so?.isActionDone &&
                        so?.frequency &&
                        so?.opportunity?.frequencyUnit
                            ? so?.frequency
                                ? `${so?.frequency || ""}/${
                                      so?.opportunity?.frequencyUnit || ""
                                  }`
                                : "--"
                            : "--",
                    Desire: so?.desire || "0",
                    Experience: so?.experience || "0",
                    Date: moment(so?.createdAt).format("MM/DD"),
                });
            });
            console.log(this.userSurveys, "userSurves");
            this.isShowDemographic = userSurveys.length > 0;
            this.demographics.age.value = this.profile?.user?.tags?.filter(
                (t) => t.category === "AGE"
            );
            this.demographics.gender.value = this.profile?.user?.tags?.filter(
                (t) => t.category === "GENDER"
            );
            this.demographics.income.value = this.profile?.user?.tags?.filter(
                (t) => t.category === "INCOME"
            );
            this.demographics.race.value = this.profile?.user?.tags?.filter(
                (t) => t.category === "ETHNICITY"
            );
            this.demographics.location.value = this.profile?.user?.tags?.filter(
                (t) => t?.type === "LOCATION"
            );
            this.interests.interest.value = this.profile?.user?.tags?.filter(
                (t) =>
                    t.category === "DESCRIPTORS" || t.category === "DESCRIPTORS"
            );
            console.log(this.demographics, "demographics");
        } catch (e) {
            console.log(e);
        } finally {
            this.isPageLoading = false;
        }
    },
};
</script>

<style scoped>
.explanation-wrapper {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0;
}
</style>
