<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto h-full" @close="open = false; $emit('onClose')">
      <div class="flex items-end justify-center text-center">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div
            class="fixed top-0 sm:top-8 inline-block sm:rounded-md h-screen sm:h-7/8 align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full ">
            <div class=" w-auto top-4 right-3 z-10 px-3 pt-3 pb-1">
              <div class="flex justify-end w-full">
                <Icon name="XIcon" size="6" isOutline color="gray-600" @click="open = false; $emit('onClose')"
                  :onClick="onNoClick" />
              </div>
            </div>
            <div class="flex flex-col h-full overflow-y-auto sm:py-3 px-4">
              <div class="mt-3 w-auto text-left pb-40">
                <div class="flex flex-col flex-1">
                  <Text size="xl" weight="semibold" color="black" :content="get(step, 'title')" />
                  <Text v-if="get(step, 'description')" size="lg" weight="" color="gray-500"
                    v-html="get(step, 'description')" custom-class="mt-4"></Text>
                  <div class="space-y-10 mt-5 p-1">
                    <div v-for="(item, i) in get(step, 'items', []).filter(s => get(s, 'category') !== 'Heading')"
                      :key="i">
                      <RadioGroup v-if="get(item, 'type') === 'RADIO'" :items="get(item, 'options')"
                        v-model:value="item.value" :name="slugify(get(item, 'label') || `_${i}`)"
                        :label="get(item, 'label')" />
                      <Input v-if="item.type === 'NUMBER' || item.type === 'TEXT'" v-model:value="item.value"
                        :label="get(item, 'label')" :type="item.type.toLowerCase()" :placeholder="item.placeholder"
                        :input-mask="item.mask" />
                      <TextArea v-if="item.type === 'TEXTAREA'" v-model:value="item.value" :label="item.label"
                        :placeholder="item.placeholder" />
                      <AutoComplete v-if="item.type === 'AUTOCOMPLETE'" :placeholder="item.placeholder"
                        :label="item.label" v-model:selected-value="item.value" :listData="item.options || []"
                        class="mt-5" />
                      <MultiSelectDropdown v-if="item.type === 'MULTISELECT'" :label="item.label"
                        v-model:selected="item.value" :options="item.options" isObj :is-full="true"
                        :description="item.helpText" :disabled="false" :radio-options="item.radioOptions" />
                      <div v-if="item.type === 'CHECKBOX' && !item.isAdd">
                        <Text size="sm" weight="semibold" color="gray-700" :content="item.label" />
                        <Text size="xs" color="gray-700" custom-class="mt-1 italic" :content="item.helpText" />
                        <CheckboxGroup v-model:selected="item.value" :items="item.options" class="mt-2" />
                      </div>
                      <div v-else-if="item.type === 'CHECKBOX' && item.isAdd">
                        <Text size="sm" weight="semibold" color="gray-700" :content="item.label" />
                        <Text size="xs" color="gray-700" custom-class="mt-1 italic" :content="item.helpText" />
                        <CheckboxListWithInput v-model:selected="item.value" placeholder="Add your own"
                          :items="item.options" class="mt-2"
                          :onAdd="(text) => onAdd(text, item)" />
                      </div>
                      <div v-if="item.type === 'BOOLEAN'">
                        <RadioGroup :items="[{ id: 0, title: 'Yes' }, { id: 1, title: 'No' }]" v-model:value="item.value"
                          :name="slugify(item && item.label || `${index}_${i}`)" :label="item && item.label" />
                      </div>
                      <div v-if="item.type === 'SLIDER'" class="pb-5">
                        <Text size="sm" weight="semibold" color="gray-700" :content="item.label" />
                        <Text v-if="item.helpText" size="xs" color="gray-700" custom-class="mt-1 italic"
                          :content="item.helpText" />
                        <Slider v-model:selected="item.value" :marks="item.mark" :min="-5" :max="5" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="step.url"
                  class="mb-3 border rounded-md border-gray-300 relative px-3 py-2 flex justify-between items-center mt-1">
                  <input v-model="step.url" disabled autocomplete="off"
                    class="border-none outline-none focus:ring-0 focus:border-none focus:outline-none w-full bg-white" />
                  <Icon @click="onCopyUrl(step.url)" name="DocumentDuplicateIcon" size="6" color="gray-500"
                    hover-text-color="indigo-500" custom-class="cursor-pointer" />
                </div>
                <!-- <EmptyState v-if="get(step, 'empty')" class="bg-gray-100" isFull v-bind="step.empty"
                  :action="onRegister" /> -->
                <div v-if="get(step, 'empty')" class="flex shadow-sm w-full items-start bg-white md:h-60 border-t pt-5">
                  <img src="https://dscvry-live-media.s3.amazonaws.com/success-img.png"
                    class="w-2/5 h-full hidden md:block" />
                  <div class="flex flex-col p-6 justify-center items-start w-full md:w-3/5 md:h-full">
                    <Text :content="get(step, 'empty.description')" size="xl" color="graytext" weight="bold" align="left"
                      custom-class="leading-snug pb-3"></Text>
                    <div class="mt-6">
                      <Button :content="get(step, 'empty.btnText')" size="md" variant="default"
                        custom-class="bg-dark_indigo text-white justify-start" @click="onRegister"></Button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="fixed w-full bottom-0 bg-gray-100 h-auto z-20">
              <div class="flex flex-row items-end justify-end my-4 mr-4 sm:mr-3 lg:mr-6">
                <Button @click="() => { open = false; $emit('onClose') }" content="Close" variant="primary"
                  customClass="ml-4 px-4" :isLoading="isLoading" />
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Button from '../../../atoms/Button/Button.vue'
import Text from '../../../atoms/Text/Text.vue'
import { ref } from "vue";
import Input from '../../../molecules/Inputs/Component/Component.vue'
import TextArea from '../../../molecules/Textareas/Simple/Simple'
import Badge from '../../../molecules/Badge/Basic/Basic.vue'
import RadioGroup from "../../../molecules/RadioGroup/Simple/Simple";
import CheckboxGroup from "../../../molecules/Checkboxes/SimpleList/SimpleList";
import AutoComplete from "../../../molecules/AutoComplete/AutoComplete";
import EmptyState from '../../../organisms/EmptyState/Simple/Simple.vue'
import MultiSelectDropdown from '../../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown.vue';
import Slider from "../../../molecules/RangeSlider/RangeSlider.vue";
import CheckboxListWithInput from "../../../molecules/Checkboxes/ListWithInput/ListWithInput.vue";
import useClipboard from 'vue-clipboard3';
import { get } from 'lodash'

import slugify from "slugify";
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Icon, Button, Text,
    Input, TextArea,
    Badge,
    RadioGroup, CheckboxGroup, Slider, CheckboxListWithInput,
    AutoComplete, EmptyState, MultiSelectDropdown
  },
  data() {
    const open = ref(this.isShow);
    return {
      player: null,
      open,
      activeIndex: 0,
      slugify,
      get,
      marks: {
        '-5': { label: 'Do not want to do at all' },
        '-4': { label: '' },
        '-3': { label: '' },
        '-2': { label: '' },
        '-1': { label: '' },
        '0': { label: 'Indifferent', },
        '1': { label: '', },
        '2': { label: '', },
        '3': { label: '', },
        '4': { label: '', },
        '5': { label: 'Desperately need to do' },
      }
    }
  },
  watch: {
    isShow: function (val) {
      this.open = val;
    }
  },
  props: {
    /**
     * Use it for modal title
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Use it for no button text
     */
    noBtnText: {
      type: String,
      default: ''
    },
    /**
     * Use it for no buttona action
     */
    onNoClick: {
      type: Function,
      default: () => {
      }
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    async onCopyUrl(url) {
			const { toClipboard } = useClipboard()
			try {
				await toClipboard(url)
				this.actions.alert.showSuccess({ message: 'Copied the url to your clipboard' })
			} catch (e) {
				console.error(e)
			}
		},
    onAdd(text, item) {
      item.options?.push({ answer: text }); item.value.push({ answer: text }) 
    }
  }
}
</script>
