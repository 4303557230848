<template>
    <div class="flex flex-col flex-1">
        <div class="flex justify-between">
            <Text
                v-if="label"
                :weight="isLarge ? 'normal' : 'semibold'"
                :size="isLarge ? 'md' : 'sm'"
                color="gray-700"
                custom-class="block"
                :content="label"
            />
        </div>
		<div class="p-2 border rounded-md">
			<editor-content :editor="editor"/>
		</div>
    </div>
</template>

<script>
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";

export default {
    components: {
        EditorContent,
    },
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        /**
         * Use to show what input form is
         */
        label: {
            type: String,
            default: "",
        },
        /**
         * Placeholder of input form
         */
        placeholder: {
            type: String,
            default: "",
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            editor: null,
        };
    },
    watch: {
        modelValue(value) {
            // HTML
            const isSame = this.editor.getHTML() === value;

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return;
            }

            this.editor.commands.setContent(value, false);
        },
    },

    mounted() {
        this.editor = new Editor({
            extensions: [StarterKit],
            content: this.modelValue,
            onUpdate: () => {
                // HTML
                this.$emit("update:modelValue", this.editor.getHTML());

                // JSON
                // this.$emit('update:modelValue', this.editor.getJSON())
            },
        });
    },

    beforeUnmount() {
        this.editor.destroy();
    },
};
</script>

<style>
.ProseMirror {        
  min-height: 100px;
}
</style>
