<template>
	<div class="p-4 sm:p-8 border rounded-md bg-white">
		<Text size="lg" color="black" weight="semibold" content="Outreach Actions" custom-class="mb-4" />
		<div class="flex flex-wrap items-center space-x-4">
			<a v-for="(item, index) in items" :key="item.id" @click="selectedIndex = index"
				class="py-2 text-xs font-semibold text-black border-b-2 flex items-center space-x-2"
				:class="selectedIndex === index ? 'border-black' : 'border-white'">
				<div>{{ item.name }}</div>
				<Icons v-if="item.isSent" name="CheckIcon" size="3" color="green-600" />
			</a>
		</div>
		<div class="flex flex-col sm:flex-row justify-between w-full space-x-0 sm:space-x-5">
			<div class="flex-1">
				<!-- <CustomSelect :items="items" label="" v-model:selectedItem="items[selectedIndex]" :isSent="isSent"
					custom-class="w-full" /> -->

				<div>
					<h4 class="mt-5 font-bold">Subject</h4>
					<p v-if="items[selectedIndex]" class="mt-1.5 text-sm" v-html="items[selectedIndex].subject"></p>
				</div>
				<div>
					<h4 class="mt-5 font-bold">Body</h4>
					<div class="mt-1.5 text-sm" v-html="getElement()">
					</div>
				</div>
				<p @click="editMessage"
					class="mt-5 text-base underline cursor-pointer hover:text-blue-800 visited:text-purple-600">
					Edit this message template
				</p>
			</div>
			<div class="flex-1">
				<ButtonWithIcon custom-class="mt-4 mr-4 sm:mr-0 sm:mt-16" label="Copy Message to Clipboard" isShowText
					iconName="DocumentDuplicateIcon" leading variant="primary" size="md" @click="copyMessage" />
				<br />
				<ButtonWithIcon v-if="get(profile, 'user.email')" custom-class="mt-2" label="Send Email" iconName="ShareIcon"
					@click="$emit('onSendEmail', items[selectedIndex])" leading isShowText variant="primary" size="md" />
				<br />
				<div class="inline-flex mt-3">
					<Switch v-model="items[selectedIndex].isSent" :class="[
						items[selectedIndex].isSent ? 'bg-primary-600' : 'bg-gray-200',
						'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
					]">
						<span class="sr-only" />
						<span aria-hidden="true" :class="[
							items[selectedIndex].isSent ? 'translate-x-5' : 'translate-x-0',
							'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
						]" />
					</Switch>
					<span class="ml-2 text-base cursor-pointer"
						@click="items[selectedIndex].isSent = !items[selectedIndex].isSent">I sent this
						message</span><br />
				</div>
				<p class="mt-2 text-base"><i>This will help update this contacts status</i></p>
				<!-- <div id="email-template w-full h-full"> -->
				<!-- <div v-html="actionSelcted.body"></div> -->
				<!-- </div> -->
			</div>
		</div>
	</div>
</template>

<script>
import { createApp, h } from 'vue';
import CustomSelect from "../Selects/Component/Component.vue";
import Link from "../../atoms/Link/Link.vue";
import ButtonWithIcon from "../Button/WithIcon/WithIcon.vue";
import Icons from "../../atoms/Icons/Icons.vue";
import Text from '../../atoms/Text/Text'
import { Switch } from "@headlessui/vue";
import useClipboard from 'vue-clipboard3';
import { stripHtml } from '../../../utils/utils.js'
import { get } from 'lodash'

export default {
	name: "DetailViewHeader",
	components: {
		Icons,
		Link,
		CustomSelect,
		ButtonWithIcon,
		Switch,
		Text,
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		selectedAction: {
			type: Object,
			default: null,
		},
		profile: {
			type: Object,
			default: () => { }
		},
		data: {
			type: Object,
			default: () => { }
		}
	},
	data() {
		return {
			items: this.items,
			isEditing: false,
			isSent: false,
			vars: {},
			selectedIndex: 0,
			get,
		};
	},
	watch: {
		selectedIndex: {
			handler(val) {
				if (val) {
					const params = this.items[val]?.metadata?.outreachTemplateParams;
					const items = {};
					params.map(param => {
						if (param === 'firstName') items[param] = this.profile?.user?.firstName
						else if (param === 'targetCustomer') items[param] = this.profile?.targetCustomerUser?.name
						else if (param === 'researchPrompt') items[param] = this.profile?.targetCustomerUser?.researchPrompt
						else if (param === 'relationship') items[param] = this.profile?.researchPrompt
						else if (param === 'interviewTime') items[param] = this.profile?.targetCustomerUser?.interviews?.find(i => i?.contact?.id === this.profile?.id)?.scheduledDateTime
						else if (param === 'contactSurveyLink') items[param] = this.profile?.targetCustomerUser?.surveys?.find(i => i?.userProfiles?.find(p => p?.id === this.profile?.id))?.publicUrl
						else if (param === 'timezone') items[param] = Intl.DateTimeFormat().resolvedOptions().timeZone
					});
					console.log(params, 'params')
					this.vars = items;
					this.isSent = !!this.profile?.sentEmails?.find(s => s?.id === val?.id)
				}
			},
			deep: true
		},
	},
	computed: {
		
	},
	methods: {
		getElement() {
			try {
				console.log(this.profile)
				const vars = this.vars;
				var body = this.items[this.selectedIndex].body;
				var matches = /<div\s+v-if="relationship\s+===\s+'strange'">[\S\s]*?<\/div>/gi.test(body);
				console.log(matches)
				if (matches) {
					if (this.profile?.relationship !== 'STRANGER') {
						body = body.replace(/<div\s+v-if="relationship\s+===\s+'strange'">[\S\s]*?<\/div>/gi, '')
					}
				}
				return body
				
				// var matches = matches[0].replace(/(<\/?[^>]+>)/gi, ''); // Strip HTML tags?
				// const getHtml = () => {
				// 	return {
				// 		props: ['firstName', 'targetCustomer', 'researchPrompt', 'relationship', 'interviewTime', 'contactSurveyLink', 'timezone'],
				// 		template: `${body}`
				// 	}
				// }
				// const tempApp = createApp(getHtml(), vars);
				// const el = document.createElement('div');
				// const mountedApp = tempApp.mount(el);
				// console.log(mountedApp, 'elment');
				// return mountedApp.$el.outerHTML
			} catch (e) {
				console.log(e)
			}
		},
		updateAction(selectedItem) {
			this.items[this.selectedIndex] = selectedItem;
		},
		editMessage() {
			this.$emit('onEditTemplate', this.items[this.selectedIndex])
		},
		async copyMessage() {
			const { toClipboard } = useClipboard()
			try {
				const html = stripHtml(this.items[this.selectedIndex].body);
				console.log(html)
				await toClipboard(html)
				this.actions.alert.showSuccess({ message: 'Copied to your clipboard' })
			} catch (e) {
				console.error(e)
			}
		},
		sendEmail() {
		},
		clickSentToggle() {
			this.items[this.selectedIndex].isSent = !this.items[this.selectedIndex].isSent;
		},

	}
};
</script>

<style>
mark {
	background: lightgrey !important;
}
</style>
